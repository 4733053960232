import React from 'react';
import { setBreadCrumbListJsonLd, useSeo } from '@bamboo/core-lib/repo/seo';

type BreadCrumbListJsonLdProps = {
  id: string;
  itemListElement: {
    '@type': string;
    position: number;
    name: string;
    item?: string;
  }[];
};

const BreadCrumbJsonLd = (props: BreadCrumbListJsonLdProps) => {
  const { id, itemListElement } = props;
  const { breadCrumbListJsonLd } = useSeo();
  const newBreadCrumbListJsonLd = {
    '@type': 'BreadcrumbList',
    '@id': id,
    itemListElement,
  };

  React.useEffect(() => {
    if (breadCrumbListJsonLd === undefined) {
      setBreadCrumbListJsonLd(newBreadCrumbListJsonLd);
    }
  }, [breadCrumbListJsonLd]);

  if (typeof window === 'undefined') {
    setBreadCrumbListJsonLd(newBreadCrumbListJsonLd);
  }

  return null;
};

export default BreadCrumbJsonLd;
