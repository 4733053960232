import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';

type Logo = {
  '@id': string;
  '@type': string;
  inLanguage: string;
  url: string;
  contentUrl: string;
  width: number;
  height: number;
  caption: string;
};

type OrganizationJsonLd = {
  '@id': string;
  '@type': string;
  name: string;
  url: string;
  sameAs: string[];
  logo: Logo;
  image: {
    '@id': string;
  };
};

type WebSiteJsonLd = {
  '@id': string;
  '@type': string;
  url: string;
  name: string;
  description: string;
  publisher: {
    '@id': string;
  };
  potentialAction: {
    '@type': string;
    target: {
      '@type': string;
      urlTemplate: string;
    };
    'query-input': string;
  }[];
  inLanguage: string;
};

type ImageObjectJsonLd = {
  '@id': string;
  '@type': string;
  url: string;
  contentUrl: string;
  inLanguage: string;
  height: number;
  width: number;
};

type WebPageJsonLd = {
  '@id': string;
  '@type': string;
  url: string;
  name: string;
  inLanguage: string;
  isPartOf: {
    '@id': string;
  };
  primaryImageOfPage: {
    '@id': string;
  };
  datePublished: string;
  dateModified: string;
  breadcrumb: {
    '@id': string;
  };
  potentialAction: {
    '@type': string;
    target: string[];
  }[];
};

type BreadCrumbListJsonLd = {
  '@id': string;
  '@type': string;
  itemListElement: {
    '@type': string;
    position: number;
    name: string;
  }[];
};

type PersonJsonLd = {
  '@type': string;
  '@id': string;
  name: string;
  image: {
    '@type': string;
    '@id': string;
    inLanguage: string;
    url: string;
    contentUrl: string;
    caption: string;
  };
  mainEntityOfPage: {
    '@id': string;
  };
};

type InitialState = {
  organizationJsonLd: OrganizationJsonLd | undefined;
  webSiteJsonLd: WebSiteJsonLd | undefined;
  imageObjectJsonLd: ImageObjectJsonLd | undefined;
  webPageJsonLd: WebPageJsonLd | undefined;
  breadCrumbListJsonLd: BreadCrumbListJsonLd | undefined;
  personJsonLd: PersonJsonLd | undefined;
};

const initialData: InitialState = {
  organizationJsonLd: undefined,
  webSiteJsonLd: undefined,
  imageObjectJsonLd: undefined,
  webPageJsonLd: undefined,
  breadCrumbListJsonLd: undefined,
  personJsonLd: undefined,
};

let store = createStore<InitialState>(() => initialData);

export const setOrganizationJsonLd = (
  organizationJsonLd: OrganizationJsonLd
) => {
  store.setState({ organizationJsonLd });
};

export const setWebSiteJsonLd = (webSiteJsonLd: WebSiteJsonLd) => {
  store.setState({ webSiteJsonLd });
};

export const setImageObjectJsonLd = (imageObjectJsonLd: ImageObjectJsonLd) => {
  store.setState({ imageObjectJsonLd });
};

export const setWebPageJsonLd = (webPageJsonLd: WebPageJsonLd) => {
  store.setState({ webPageJsonLd });
};

export const setBreadCrumbListJsonLd = (
  breadCrumbListJsonLd: BreadCrumbListJsonLd
) => {
  store.setState({ breadCrumbListJsonLd });
};

export const setPersonJsonLd = (personJsonLd: PersonJsonLd) => {
  store.setState({ personJsonLd });
};

export function hydrate(initialData: InitialState) {
  store = createStore<InitialState>(() => initialData);
}

export function state() {
  return store.getState();
}

export function reset(
  newValue?: (current: InitialState) => Partial<InitialState>
) {
  const current = store.getState();
  store.setState({ ...initialData, ...(newValue?.(current) ?? {}) });
}

export const useSeo = () => useStore(store, (s) => s);
